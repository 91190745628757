var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"新增VPDN分组"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"simiot-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"VPDN分组名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入VPDN分组名称' },
                { max: 50, message: '最多50个字符' } ],
            } ]),expression:"[\n            'name',\n            {\n              normalize: this.$lodash.trim,\n              rules: [\n                { required: true, message: '请输入VPDN分组名称' },\n                { max: 50, message: '最多50个字符' },\n              ],\n            },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"VPDN IP"}},[_c('a-textarea',{style:({
            color: this.validateStatus === 'error' ? 'red' : '',
            height: '150px',
          }),attrs:{"placeholder":"请输入IP，一行一个IP地址"},on:{"blur":_vm.validateIccids},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"inputValue"}})],1),_c('a-form-item',{attrs:{"label":"描述"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'description',
            {
              normalize: this.$lodash.trim,
              rules: [{ max: 255, message: '最多255个字符' }],
            } ]),expression:"[\n            'description',\n            {\n              normalize: this.$lodash.trim,\n              rules: [{ max: 255, message: '最多255个字符' }],\n            },\n          ]"}]})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }