<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="新增VPDN分组"
      @ok="handleSubmit"
    >
      <a-form
        class="simiot-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="VPDN分组名称">
          <a-input
            v-decorator="[
              'name',
              {
                normalize: this.$lodash.trim,
                rules: [
                  { required: true, message: '请输入VPDN分组名称' },
                  { max: 50, message: '最多50个字符' },
                ],
              },
            ]"
          />
        </a-form-item>

        <a-form-item label="VPDN IP">
          <a-textarea
            placeholder="请输入IP，一行一个IP地址"
            v-model.trim="inputValue"
            :style="{
              color: this.validateStatus === 'error' ? 'red' : '',
              height: '150px',
            }"
            @blur="validateIccids"
          />
        </a-form-item>

        <a-form-item label="描述">
          <a-input
            v-decorator="[
              'description',
              {
                normalize: this.$lodash.trim,
                rules: [{ max: 255, message: '最多255个字符' }],
              },
            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { createVpdn } from '@/api/vpdn'
export default {
  name: 'NewVpdn',
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: '', ips: '' }),
      submitting: false,
      inputValue: '',
      validateStatus: '',
      errorMsg: ''
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    agentId() {
      return parseInt(this.$route.params.id)
    }
  },
  created() {},
  methods: {
    validateIccids() {
      // 未输入内容
      if (!this.inputValue) {
        return
      }

      // 处理行
      const { validIps, errorText } = this.analyzeIccids(this.inputValue)

      // 处理错误
      if (errorText) {
        this.validateStatus = 'error'
        this.errorMsg = 'ip填写错误'
      } else {
        this.validateStatus = 'success'
        this.errorMsg = null
      }

      // 拼接 textarea 内容(错误的放到最上面)
      this.inputValue = errorText + validIps.join('\n')
    },

    analyzeIccids(inputText) {
      const that = this

      // 将换行符替换，替换所有的英文中文以及:
      const ips = inputText
        .replace(/([^\u0000-\u00FF])/g, '')
        .replace(/:/g, '')
        .replace(/[\u4E00-\u9FA5]/g, '')
        // .replace(/[\u4E00-\u9FA5]|[A-Za-z]/g, '')
        .replace(/\r*\n/g, 'ips')
        .split('ips')
      var validIps = []
      var errorText = ''

      ips.forEach(function(value) {
        value = value.trim()
        if (!value) {
          return
        }
        errorText += that.isValid(validIps, value)
      })
      return { validIps: validIps, errorText: errorText }
    },

    isValid(validIps, value) {
      var errorText = ''
      //   var reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
      //   var isValid = reg.test(value)
      var isValid = true
      var ip = isValid ? value : 'IP格式不正确'
      if (isValid) {
        if (!validIps.includes(ip)) {
          validIps.push(ip)
        }
      } else {
        errorText += value + ':' + ip + '\n'
      }
      return errorText
    },

    handleSubmit(e) {
      e.preventDefault()

      if (this.errorMsg) {
        return
      }

      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true

          if (this.inputValue) {
            var ips = this.inputValue.replace(/\r*\n/g, 'ips').split('ips')
            values.ips = JSON.stringify(ips)
            values.ips_count = ips.length
          } else {
            values.ips = JSON.stringify([])
          }

          values.agent_id = this.agentId

          createVpdn(values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
